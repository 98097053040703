<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow">
      <q-form
        @submit="createCustomer"
        @reset="data.selCustomer = {}"
        class="column "
      >
        <div class="q-pa-md">
          <div class="column q-gutter-xs">
            <label class="text-bold">
              <span class="text-red">*</span> Tipe Customer :</label
            >
            <q-select
              outlined
              :options="data.type"
              v-model="data.selCustomer.type"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Tipe Tidak Boleh Kosong!',
              ]"
            ></q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold">
              <span class="text-red">*</span> Kota :</label
            >
            <q-select
              outlined
              :options="data.city"
              v-model="data.selCustomer.city"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Kota Tidak Boleh Kosong!',
              ]"
            ></q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold">
              <span class="text-red">*</span> Application :</label
            >
            <q-select
              outlined
              :options="data.application"
              v-model="data.selCustomer.application"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) || 'Application Tidak Boleh Kosong!',
              ]"
            ></q-select>
            <q-input
              outlined
              v-if="data.selCustomer.application == 'Other'"
              v-model="data.selCustomer.application_other"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) || 'Application Tidak Boleh Kosong!',
              ]"
            ></q-input>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold">
              <span class="text-red">*</span> Nama Customer :</label
            >
            <q-input
              outlined
              v-model="data.selCustomer.name"
              placeholder="Ex: PT.A, CV.B"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Nama Tidak Boleh Kosong!',
              ]"
            ></q-input>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold">
              <span class="text-red">*</span> Kontak :</label
            >
            <q-input
              outlined
              placeholder="EX: (021) 123456"
              v-model="data.selCustomer.contact"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) || 'Kontak Tidak Boleh Kosong!',
              ]"
            ></q-input>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Kontak Detail :</label>
            <q-input
              outlined
              type="textarea"
              placeholder="Ex: Bapak A - Jabatan B"
              v-model="data.selCustomer.detail"
            ></q-input>
          </div>
          <p class="text-red text-italic q-mt-md">*Wajib diisi</p>
        </div>
        <q-btn
          class="no-border-radius"
          color="primary"
          label="DAFTARKAN CUSTOMER"
          type="submit"
        ></q-btn>
      </q-form>
    </q-scroll-area>
  </div>
</template>
<script>
import { onMounted } from "vue";
import useCustomer from "./useCustomer";
export default {
  setup() {
    const {
      createCustomer,
      data,
      getApplication,
      getType,
      getCity,
      $q,
    } = useCustomer();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getApplication();
        await getType();
        await getCity();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      createCustomer,
      data,
    };
  },
};
</script>
